<template>
    <v-img height="634px" class="align-center" :src="image">
          <v-row>
            <v-col
              lg="11"
              cols="12"
              :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
            >
              <div
                @click="editar(edit_title)"
                v-if="!$vuetify.breakpoint.smAndDown"
                v-html="title"
                class="textoImagenPcSmall"
              ></div>
              <div
                v-else
                @click="editar(edit_title)"
                v-html="title"
                class="textoImagenMobileSmall"
              ></div>
              <div>
                
              </div>
            </v-col>
            <v-col style="padding-left: 5%;" cols="11" :class="$vuetify.breakpoint.smAndDown ? 'text-center': ''">
                <v-btn color="#000000" @click="$router.push({name: redirect})" dark large>{{ button_name }}</v-btn>
            </v-col>
          </v-row>
        </v-img>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        edit_title: {
            type: String,
            required: true
        },
        image: {
            type: String,
            required:  true
        },
        button_name:  {
            type: String,
            default: 'Contacto'
        },
        redirect: {
          type: String,
          default: 'home'
        }
    },
    data() {
        return {

        }
    }
}
</script>