export const HOME_PAGE={
    'img_0': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1693777407/Grobe_imgs/Pag_Home/header/HEADER_HOME_2_Mesa_de_trabajo_1_1_ly02kx.png',
    'img_1': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133070/Img%20Omni%20WEB/Pag%20HOME/Seccion%20Servicios/Home_Service_Storage_309x327_hwpehn.png',
    'img_2': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133069/Img%20Omni%20WEB/Pag%20HOME/Seccion%20Servicios/Home_Service_Land_Transport__309x327_k9pbts.png',
    'img_3': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133067/Img%20Omni%20WEB/Pag%20HOME/Seccion%20Servicios/Home_Service_import_309x327_hyraqo.png',
    'img_4': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133070/Img%20Omni%20WEB/Pag%20HOME/Seccion%20Servicios/Home_Service_TransportandDistribution_309x327_gjkttz.png',
    'img_5': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1665537610/Grobe_imgs/Pag_Home/section%20Reason%20Why/soluciones_74x74_lopruf.png',
    'img_6': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1665537610/Grobe_imgs/Pag_Home/section%20Reason%20Why/trazabilidad_74x74_srorqz.png',
    'img_7': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1665537610/Grobe_imgs/Pag_Home/section%20Reason%20Why/tarifas_74x74_nrx1am.png',
    'img_8': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1665537610/Grobe_imgs/Pag_Home/section%20Reason%20Why/seguridad_74x74_ahd2zs.png',
    'img_9': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1665537607/Grobe_imgs/Pag_Home/Section%20Coverage/Cobertura-01_2938x1828_xny9et.png',
    'img_10': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1665537607/Grobe_imgs/Pag_Home/Section%20Contact%20Us/Contacto_5572x3134_efswxz.jpg',
    'img_11': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1665537607/Grobe_imgs/Pag_Home/Section%20Contact%20Us/ContactUs_5572x3134_awqiql.jpg',
    'img_12': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133059/Img%20Omni%20WEB/Pag%20HOME/Seccion%20Cartified%20and%20Safe/Home__Certified_376x214_b8vvbe.png',
    'img_13': [
        'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642182261/Omni/Home/Certificado_logo_fast_107.92x30.35_sayzkz.png',
        'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642182262/Omni/Home/CTPAT_140.52x46.09_hckgfd.png',
        'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642182262/Omni/Home/iso_91.06x41.59_fems2p.png',
        'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642182262/Omni/Home/UIIAL_115.79x38.35_rs3dkd.png',
        'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642182261/Omni/Home/Logo_SCT_97.74x40.54_kf2fx8.png',
        'https://res.cloudinary.com/omni-international-logistics/image/upload/v1665546581/Img%20Omni%20WEB/Pag%20HOME/Seccion%20Certification/agua-del-inodoro_1.jpg_1902800913_trunbj.webp',
        'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642182262/Omni/Home/logo_transporte-limpio_84.71x67.33_qoxspv.png',
        'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642182262/Omni/Home/iso_int_55.02x59.37_ehxzuu.png',
        'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642182262/Omni/Home/OEA_81.81x44.16_nkpolt.png',
    ],
}

export const ABOUT_US = {
    'img_0': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133045/Img%20Omni%20WEB/Pag%20ABOUT%20US-%20Nosotros/header/HEADER_ABOUT_US_1366x634_vmety5.png',
    'img_1': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1685579039/Grobe_imgs/Pag_Nosotros/Section%20Grobe%20Headquaters/Aboutus_Headquaters_3346x1893_yrarwh.png',
}

export const SERVICES = {
    'img_0': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133072/Img%20Omni%20WEB/Pag%20SERVICE-%20servicio/HEADER_SERVICE__elevate-GAdkOpqbTfo-unsplash_4_aziai4.png',
    // 'img_1': '@/assets/Iconos/GraphicStar.svg',
    // 'img_2': '@/assets/Iconos/NoteText.svg',
    // 'img_3': '@/assets/Iconos/Clock.svg',
    // 'img_4': '@/assets/Iconos/Locate.svg',
    // 'img_5': '@/assets/Iconos/Box.svg',
    'img_6': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1665537612/Grobe_imgs/Pag_Servicio/contacto_466x306/Service_llamanos_466x306-01_bvdois.jpg',
    'img_7': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1665537612/Grobe_imgs/Pag_Servicio/contacto_466x306/Service_ContactUs_466x306-01_av1nxi.jpg',
    'img_8': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1684018825/Omni/Services/sad/interior-almacenamiento-almacen-estantes-cargados-mercancias_van2on.jpg',
    'img_9': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1684018896/Omni/Services/sad/mujer-que-trabaja-almacen_c0jipg.jpg',
    'img_10': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1684019138/Omni/Services/tam/SERVICE__Transportation_438x155_cyqfoa.png',
    'img_11': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1684019192/Omni/Services/tam/Section_Service_Industry_Transportation_466x306_thgvld.png'
}

export const COVERAGE = {
    'img_0': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133059/Img%20Omni%20WEB/Pag%20COVERAGE-%20cobertura/header/header_Coverage_1366x_634_mof8sq.png',
    'img_1': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1685140360/Grobe_imgs/Pag_Coverage-cobertura/coverage_1191x1226-01_bn7dq2.png',
    'img_2': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1642133050/Img%20Omni%20WEB/Pag%20COVERAGE-%20cobertura/continente_gris_2_icxfrd.png',
    'img_3': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1665552414/Grobe_imgs/Pag_Coverage-cobertura/Coverage__Mex_562x360-03_1_pqgq7f.png',
    'img_4': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1665552414/Grobe_imgs/Pag_Coverage-cobertura/Coverage__USA_509x324-04_1_fjf8ov.png',
}

export const CONTACT_US = {
    'img_0': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1665537606/Grobe_imgs/Pag_ContactUs-contacto/contactus_4096x2304_euakv8.jpg',
    'img_1': 'https://res.cloudinary.com/omni-international-logistics/image/upload/v1665537604/Grobe_imgs/Pag_ContactUs-contacto/contacto_4096x2304_x0fcso.jpg',
    'img_2': '',
}