<template>
    <v-container fluid style="padding: 0 10% 0 10%;">
      <v-row justify="center">
        <v-col lg="6" md="6" sm="12" cols="12">
            <v-col @click="editar(edit_title)" class="title-parrafo" cols="12">
                {{ title }}
            </v-col>
            <v-col @click="editar(edit_subtitle)" class="simple-parrafo" cols="12">
                {{ subtitle }}
            </v-col>
            <v-col cols="12">
                <v-btn color="#E30707" dark @click="$router.push({name: redirect_name})">{{ button_name }}</v-btn>
            </v-col>
        </v-col>
        <v-col lg="6" md="6" sm="12" cols="12">
            <v-img :aspect-ratio="16/12" max-height="400px" :src="image"></v-img>
        </v-col>
      </v-row>  
    </v-container>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        edit_title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        },
        edit_subtitle: {
            type: String,
            required: true
        },
        button_name:  {
            type: String,
            default: 'Más Información'
        },
        image: {
            type: String,
            required: true
        },
        redirect_name: {
            type: String,
            default: 'home'
        }
    }
}
</script>